<template>
    <div>
        <CityMap v-if="currentCityCode!==null" />
        <ChinaMap v-else></ChinaMap>
    </div>
</template>

<script>
import ChinaMap from './ChinaMap'
import CityMap from './CityMap'

export default {
  components: {
    ChinaMap, CityMap
  },
  computed: {
    currentCityCode () {
      return this.$store.state.adx.currentCityCode
    }
  }
}
</script>
