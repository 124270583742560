<template>
    <div>
        <div class="text-center">
          <Button type="primary" @click="handleRestore">返回全国地图</Button>
        </div>

        <SvgLineAdx ref="svgline" @map-finished="handleMapLoadFinished" :currentCategory="categoryId" :currentCityCode="currentCityCode" @on-click-station="handleClickStation"/>

        <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="currentSvgType"
          :assetIds="JSON.stringify(mapShowAssets)" :duration="purchaseDuration" :searchSchedules="searchSchedules" :orderId="orderBean.id"
          showStationPortray
          @svg-finished="svgFinished" @on-purchase="handlePurchaseSvgResource"
          @handleChange3dMode="handleBeginRevice3d" />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />
    </div>
</template>

<script>
import SvgLineAdx from '@/components/svg/SvgLineAdx'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { deleteResource } from '@/api/order/purchase'
import { addAdxResources } from '@/api/order/adxorder'

export default {
  components: {
    SvgLineAdx, SvgStation, ThreeStation
  },
  data () {
    return {
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0,
      searchSchedules: []
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
      // 格式化显示的站点
        const showStations = this.stations.filter(x => this.mapShowAssets.includes(x.assetId))

        if (this.mapType === 'flicker') {
          this.$refs.svgline.loadFlickerStations(showStations)
        } else {
          this.$refs.svgline.loadResourceStations(showStations, 'order')
        }
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    hideMapOtherAsset (assetIds) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        this.$refs.svgline.hideOtherAsset(assetIds)
      } else {
        setTimeout(() => {
          this.hideMapOtherAsset(assetIds)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    svgFinished () { // SVG站点地图加载完毕
      this.$refs.svgStation.initShowBrand()
      this.searchSchedules = this.checkSchedules
      const resourceSearchData = {
        orderId: this.orderBean.id,
        stationIds: this.chooseStationId,
        schedules: (this.searchSchedules.length > 0) ? JSON.stringify(this.searchSchedules) : null,
        // customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        // const resourceList = res.list.map(item => item.resourceId)
        if (this.currentSvgType === 2) {
          this.$refs.svgStation.tagPurchase(res.list)
        } else {
          this.$refs.svgStation.tagResource(res.list)
        }

        this.$refs.svgStation.choseSpinShow()
      })
    },
    handlePurchaseSvgResource (params) {
      let postData = null

      if (params.isDeleted) {
        const deleteResourcePromise = []
        params.checkSchedules.forEach(item => { // 根据选中的档期，批量删除点位, 后端不支持批量档期删除
          postData = {
            orderId: this.orderBean.id,
            deviceId: params.deviceId,
            startDate: item.startDate,
            endDate: item.endDate
          }
          deleteResourcePromise.push(deleteResource(postData))
        })
        Promise.race(deleteResourcePromise).then(res => {
          if (res) {
            // 还原资源颜色
            this.$refs.svgStation.changeResourceColor(params.deviceId, false, params.elementNodeName)
            this.$Notice.success({ desc: '删除资源成功！' })
          }
        })
      } else {
        postData = {
          orderId: this.orderBean.id,
          deviceId: params.deviceId,
          schedules: JSON.stringify(params.checkSchedules),
          conditions: JSON.stringify(this.searchCondition),
          duration: this.purchaseDuration ? this.purchaseDuration : null
        }
        addAdxResources(postData).then(res => {
          if (res.errcode === 0) {
            // 设置当前选中资源颜色
            this.$refs.svgStation.changeResourceColor(params.deviceId, true, params.elementNodeName)
            this.$Notice.success({ desc: '添加资源成功！' })
          }
        })
      }
    },
    handelRefreshMap () {
      // 初始化地图已加载余量，且底部数据未别选中
      if (this.mapType === 'full' && this.bottomProduct === null) {
        this.$store.commit('set_adx_update_left', new Date())
      }
      this.$store.commit('set_adx_update_purchase', new Date())
    },
    handleRestore () {
      this.$store.commit('set_adx_currentCityCode', null)
      setTimeout(() => {
        this.$store.commit('set_adx_update_China_map', new Date())
      }, 500)
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  computed: {
    categoryId () {
      return this.$store.state.adx.categoryId
    },
    currentCityCode () {
      return this.$store.state.adx.currentCityCode
    },
    orderBean () {
      return this.$store.state.adx.orderBean
    },
    beginUpdateMap () {
      return this.$store.state.adx.beginUpdateMap
    },
    stations () {
      return this.$store.state.adx.stations
    },
    mapType () {
      return this.$store.state.adx.mapType
    },
    searchCondition () {
      return this.$store.state.adx.searchCondition
    },
    purchaseDuration () {
      return this.$store.state.adx.purchaseDuration
    },
    orderDefaultSchedules () {
      const orderSchedules = this.orderBean.orderScheduleList

      if (orderSchedules) {
        return orderSchedules.map(x => {
          return {
            startDate: x.startDate,
            endDate: x.endDate,
            label: GetCurrentSchedule(x.startDate, x.endDate)
          }
        })
      } else {
        return []
      }
    },
    checkSchedules () {
      return this.$store.state.adx.checkSchedules
    },
    currentSvgType () {
      let type = 3
      if (this.$store.state.sys.leftComponent === 'left_OrderEdit') {
        type = 2
      }

      return type
    },
    mapShowAssets () {
      return this.$store.state.adx.showAssets
    },
    bottomProduct () {
      return this.$store.state.adx.bottomProduct
    }
  },
  watch: {
    beginUpdateMap (val) {
      this.LoadMapData()
    },
    mapShowAssets (val) {
      if (val.length > 0) {
        this.hideMapOtherAsset(val)
      }
    },
    orderDefaultSchedules (val) {
      this.searchSchedules = val
    },
    stationSvgModal (val) {
      if (!val) {
        // 刷新地图选中的资源数量
        this.handelRefreshMap()
      }
    }

  }

}
</script>
